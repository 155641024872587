import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const UpArrow = memo(function UpArrow(props) {
    return (<svg width='6' height='6' viewBox='0 0 14 14'>
      <polygon transform='rotate(0,7,7)' points='7 2 14 12 0 12' fill={props.fill}/>
    </svg>);
});
export const DnArrow = memo(function DnArrow(props) {
    return (<svg width='6' height='6' viewBox='0 0 14 14'>
      <polygon transform='rotate(180,7,7)' points='7 2 14 12 0 12' fill={props.fill}/>
    </svg>);
});
export const SortArrow = memo(function SortArrow(props) {
    //我不知道store的type
    //有用到 trustDataStore、useSortSignalrDataStore
    const sortSnap = useSnapshot(props.sotre);
    const desc = sortSnap.orderKey === 'desc' && props.sortKey === sortSnap.sortKey;
    const asc = sortSnap.orderKey === 'asc' && props.sortKey === sortSnap.sortKey;
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const handleSortSelected = useCallback((sortKey) => {
        props.sotre.sortKey = sortKey;
        props.sotre.orderKey = toggleOrderKey;
    }, [props.sotre, toggleOrderKey]);
    return (<div css={css `
          ${fill_vertical_all_center};
          height: 14px;
          width: 10px;
          cursor: pointer;
        `} onClick={() => {
            handleSortSelected(props.sortKey);
        }}>
        <div css={fill_vertical_all_center}>
          <UpArrow fill={asc === true ? '#ffffff' : '#555c73'}/>
        </div>
        <div css={fill_vertical_all_center}>
          <DnArrow fill={desc === true ? '#ffffff' : '#555c73'}/>
        </div>
      </div>);
});
