import pWaitFor from 'p-wait-for';
import { useEffect } from 'react';
import { useAsyncFn, useInterval, useMount, useUpdateEffect } from 'react-use';
import { apirc } from '~/configs/apirc';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { SignalrMethod } from '~/modules/SDK/Signalr/SignalrMethod';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalrSyncSubscriptions2 } from '~/modules/SDK/Signalr/useSignalrSyncSubscriptions2';
import { fr_events } from '~/pages/heineken_template/_fr/fr_events';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { delay } from '~/utils/delay';
/**
 * # 改用 V2.1 的 useSignalrStart2_1 替代
 *
 * @deprecated
 */
export const useSignalrStart2_0 = (options) => {
    const autoReConnectInterval = options?.reconnectCheckInterval ?? 30000;
    const uid = useMeStore(state => state.meUserState?.uid) || null;
    const [connection, start] = useAsyncFn(async () => {
        if (signalrStore2.isDisconnected) {
            await signalrStore2.start();
        }
        // 避免 state === connected 但好像實際沒有 connected
        await delay(1000);
        await pWaitFor(() => signalrStore2.isConnected === true, { interval: 150, timeout: 5000 });
        await signalrStore2.invokeSubscriptions();
        return signalrStore2.connection;
    }, []);
    useMount(() => {
        debugAPI.signalrV2.log(`.useSignalrStart2_0()`);
    });
    useMount(() => {
        start();
    });
    // 根據 uid 變化，使後端知曉
    useEffect(() => {
        if (connection.value && !connection.loading) {
            signalrStore2.connection.invoke(SignalrMethod.SetUid, uid).catch((error) => {
                if (error.message.includes('Method does not exist')) {
                    console.error(`[signalr/react@2] 在 ${fr_agents.agent} 的後端尚未支持 ${SignalrMethod.SetUid} 方法，如須此特性，請聯絡後端支持`, { wsURL: apirc.signalr.wsURL });
                }
                else {
                    console.error(error.message);
                }
            });
        }
    }, [connection.loading, connection.value, uid]);
    // 定期檢查斷線重連
    useInterval(() => {
        if (connection.loading || document.visibilityState === 'visible')
            return;
        start();
    }, autoReConnectInterval);
    // 確保連線機制（使 報價UI 接續渲染）
    // - 例如：當手機休眠後喚醒後會斷線
    useUpdateEffect(() => {
        const removeEventOfOnVisible = fr_events.on.documentVisibilityChange.onVisible(() => {
            start();
        });
        const removeEventOfOnHidden = fr_events.on.documentVisibilityChange.onHidden(() => {
            signalrStore2.stop();
        });
        return function GC() {
            removeEventOfOnVisible();
            removeEventOfOnHidden();
        };
    }, [signalrStore2.connection.state]);
    useSignalrSyncSubscriptions2();
};
